import React , {Component} from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ForecastCard from './forecast-card';
import './forecast.css';
import RPCChart from './forecast-rpc-chart';
import ForecastContainer from './forecast-container';



const BUYERFORECASTQUERY = gql`
query buyerSchedules($day:Date!, $campaign_id:String){
   buyerschedules(date:$day, campaign_id:$campaign_id){
        _id
        buyer
        name
        total_raw
        total_paid
        total_revenue
        running_rpc
        total_conversion
        start_date
        calls_tracked
        line_items{
            _id
            forecasted
            start_time
            end_time
            raw
            paid
            revenue
            rpc
            running_rpc
            total_raw
            total_paid
            total_revenue
            total_conversion
            variance
            variance_percentage
            conversion
        }
    }
}
`;


class Forecast extends Component {
    constructor(props){
        super(props);

        this.state = {
            day:moment(new Date()).startOf('day'),
        }

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange(dateName,value){
        this.setState({[dateName]:value, [`${dateName.replace('_date','')}`]:value});
    }

    render(){
        // return <div>Forecasts</div>
        return(
            <div>
                <div className="report-parameters">
                    <h2 className="header">Buyer Forecast </h2>
                    <div className="fields">
                            <div className="form-group">
                                <label htmlFor="start">Date</label>
                                <DatePicker selected={this.state.day}
                                            onSelect={(date)=>{this.handleDateChange("day", date)}}
                                            className="form-control"
                                    />
                            </div>
                    </div>
                </div>
                
                <div>
                <Query query={BUYERFORECASTQUERY} variables={this.state} pollInterval={10000}>
                    
                    {({loading, error, data, time_stamp=new Date().valueOf(), stopPolling, startPolling}) =>{
                        if(loading) return `${'Loading'}`;
                        if(error) return stopPolling();
                        return(
                            <div>
                                <div className="forecast-chart">
                                    <RPCChart data={data.buyerschedules} 
                                                x_accesor="name" 
                                                y_data="running_rpc" 
                                                timeStamp={time_stamp} 
                                                height={100} 
                                                format="currency" 
                                                title="RPC" type="line"/>
                                    <RPCChart data={data.buyerschedules} 
                                                x_accesor="name" 
                                                y_data="conversion" 
                                                timeStamp={time_stamp} 
                                                height={100} 
                                                format="percent" 
                                                title="Conversion" type="bar"/>
                                </div>
                                <ForecastContainer forecasts={data.buyerschedules}/>
                            </div> 
                        );   
                    }}
                </Query>
                </div> 
            </div>
        )
    }
}


export default Forecast;