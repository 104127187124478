import React, {Component} from 'react';
import { ApolloClient } from 'apollo-client';
import {ApolloProvider} from 'react-apollo';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import {RestLink} from 'apollo-link-rest';
import {withUser} from './context/userContext';
import { withRouter } from "react-router-dom";


import config from './apolloClient.json';

console.log(config);
// const restLink = new RestLink({
// uri:"http://localhost:8446/api/v2"
// });





class ApolloProviderCustom extends Component{
  constructor(props){
    super(props);
    if(this.props.authenticated === false){
      this.props.history.push('/login');
    }
    this._authLink = setContext((_,{headers})=>{
      return {
        headers:{
          ...headers,
          tgh_token: this.props.token
        }
      }
    });

    this.cleanTypeName = new ApolloLink((operation, forward)=>{
      if (operation.variables) {
        const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
      }
      return forward(operation).map((data) => {
        return data;
      });
    });
    
    this._client = new ApolloClient({
      link: this._authLink.concat(ApolloLink.from([
        this.cleanTypeName,
        onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path, extensions}) => {
              if(extensions){
                if(extensions.code === "UNAUTHENTICATED"){
                  console.log('loggin out from apollo client');

                  props.logout(this._client);
                  //todo pass coming from so that user is redirected correctly
                  this.props.history.push('/login');
                }
              }
              console.log(
                `[GraphQL error]: Location: ${JSON.stringify(locations)}, Path: ${path}, code:${extensions.code}`,
              )
            }
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        createUploadLink({
          uri: config.uri,
        }),
    

      ])),
      cache: new InMemoryCache(),
      
    });

  }


  
  render(){
    return(
      <ApolloProvider client={this._client}>
          {this.props.children}
      </ApolloProvider>
    )
  }
}

export default  withRouter(withUser(ApolloProviderCustom));