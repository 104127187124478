import React, {Component} from 'react';
import Link from 'react-router-dom/Link';
import privateRoute from '../private-route/private-route';


class Reports extends Component {
    constructor(props){
        super(props);
    }

    render(){
        // TODO: Make this a list of reports from database that we can run (pre defined)
        return  (
            <div className="card report-card">
            <div className="card-header">
                <h2>Reports</h2>
            </div>
            <div className="card-body">
                <ul className="list-group">
                    <li className="list-group-item">
                        <Link to="/reports/overview">Overview </Link>
                    </li>
                    <li className="list-group-item">
                        <Link to="/reports/compare-logs">Compare Logs </Link>
                    </li>
                    <li className="list-group-item">
                        <Link to="/reports/spend">Spend </Link>
                    </li>
                   
                </ul>
                
            </div>

            </div>
        )
    }
}

export default privateRoute({requireLoggedIn:true})(Reports);