import React, {Component} from 'react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import './BuyerForecast.css';

const BuyerForecastsMutation  = gql`
    mutation updateBuyerSchedule($campaign_id:String!, $schedule:BuyerScheduleDefinitionInput!){
        updateBuyerSchedule(campaign_id:$campaign_id, buyerSchedule:$schedule){
            _id,
            name,
            buyer,
            buyer_id,
            calls_tracked,
            active,
            total_calls
            start_time
            end_time
            line_items {
                forecasted
                start_time
                end_time
                calls_expected
            }
            email
            custom
        }
    }
`;

const AdvertiserCampaigns = gql`
query getAdvertisersCampaigns($name:String!){
    advertiserbyName(name:$name){
        buyer
        buyer_id
        campaigns{
          buyer_campaign
          buyer_campaign_id
        }
    }
}
`;

const Advertisers = gql`
 query getAdvertiser{
     advertisers{
        buyer
        buyer_id
        campaigns{
            buyer_campaign
            buyer_campaign_id
        }
    }
 }
`;

const CAMPAIGN = gql`
query campaignby_id($_id:String!){
    campaignby_id(_id:$_id){
        _id
        name
        buyers{
            buyer
            campaigns
        }
        publishers{
            publisher
            campaigns
        }
        creatives
        buyerSchedules{
            _id
            name
            buyer
            campaigns{
                buyer_campaign_id
                buyer_campaign
            }
            buyer_id
            calls_tracked
            active
            total_calls
            start_time
            end_time
            line_items{
                start_time
                end_time
                start_date
                end_date
                forecasted
            }
            email 
            custom
        }
    }
   
    
}
`   

class BuyerCampaigns extends Component {

    constructor(props){
        super(props);
        console.log(props);
        this.state = {active_campaigns:props.active_campaigns,campaigns:props.campaigns};
        if(props.active_campaigns){
            // check for matches
            let active_index = {};

            props.active_campaigns.map(d=>active_index[d.buyer_campaign]=d);
            let available = [];
            props.campaigns.map((d)=>active_index[d.buyer_campaign]?null:available.push(d));
            console.log(available);

            this.state = {active_campaigns : props.active, campaigns:[...available]}
        }
        this.removeCampaign = this.removeCampaign.bind(this);


    }

    componentDidUpdate(prevProps){
        if (prevProps.active !== this.props.active) {
            this.setState({active_campaigns:this.props.active})
        }
        if (prevProps.campaigns !== this.props.campaigns){
            // check for matches
            let active_index = {};
            let available = [];
            this.props.campaigns.map((d)=>active_index[d.buyer_campaign]?null:available.push(d));
            console.log('campaigns updated', this.props.campaigns, prevProps.campaigns, available);
            this.setState({campaigns:available, active_campaigns:[]},()=>{
                console.log('component updated')
            })
        }
    }

    removeCampaign(index){
        let campaigns = [...this.state.campaigns];
        campaigns.splice(index,1);
        this.setState({campaigns:campaigns});
    }

    restoreCampaign(campaign){
        this.setState({campaigns:[]})
    }


    render(){
        return(
            <div className="available-campaigns">
            <h4 className="text-center">Available Campaigns</h4>
                <ul className="list-group">
                    {this.state.campaigns?this.state.campaigns.map((campaign, i)=>{
                        return (
                            <li className="list-group-item" key={campaign.buyer_campaign}>
                                <span>{campaign.buyer_campaign}</span>
                                <button className="btn btn-sm btn-success" 
                                        onClick={(e)=>{e.preventDefault();
                                                this.props.addCampaign(campaign);
                                                this.removeCampaign(i)}}>
                                    <i className="fa fa-plus"/>
                                </button>
                            </li>
                        )
                    }): <h2>No Campaigns </h2>}
                
                </ul>
                
            </div>
        );
    }
}

class BuyerForecast extends Component{
    constructor(props){
        super(props);
        console.log(props);
        this.state = {...props.forecast, buyer_index:null};
        this.handleForecastUpdate = this.handleForecastUpdate.bind(this);
        this.addCampaign = this.addCampaign.bind(this);
        this.removeCampaign = this.removeCampaign.bind(this);
        this.campaigns = this.campaigns.bind(this);
        this.handleScheduleChange = this.handleScheduleChange.bind(this);
        this.setBuyer = this.setBuyer.bind(this);
        this.mutateForecast = this.mutateForecast.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    setBuyer(e,data){
        this.setState({buyer_index:e.target.value, campaigns:[], buyer:data[e.target.value].buyer});
    }

    async handleScheduleChange(e, i, item){
        let value = e.target.value;
        //update forecast line items

        let item_to_update = {...item};
        let array = [...this.state.line_items];
        item_to_update["forecasted"] =  parseInt(value);
        array[i] = item_to_update;
        this.setState({line_items:array});
    }

    handleForecastUpdate(e, optional){
        let name;
        let value;
        let valueToAdd;
        let target;
        if(e){
            if(e["toDate"]){
                name=optional;
                valueToAdd=e.toDate();
            }else{
                target = e.target;
                name = target.name;
                value = target.value;
                valueToAdd = target.value;
                if(target.type === "number"){
                    valueToAdd = parseInt(value);
                }
            }
            this.setState({[name]:valueToAdd});
        }
       
    }

    addCampaign(campaign){
        let campaign_to_add = {...campaign};
        delete campaign_to_add.__typename;
        delete campaign_to_add["Symbol(id)"];

        let index = {};
        let campaigns = this.state.campaigns.map((d)=>{
            index[d.buyer_campaign] = d;
            let item =  {...d};
            delete item.__typename;
            delete item["Symbol(id)"];
            return item;
        });

        if(!index[campaign.buyer_campaign]){
             campaigns.push(campaign_to_add);
             this.setState({campaigns:campaigns});
        }
    }

    removeCampaign(index){
        let to_remove = [...this.state.campaigns];
        to_remove.splice(index, 1);
        this.setState({campaigns:to_remove});
    }


    campaigns(){
        if(this.props.new){
        //create empty forecast todo:

        return(
            <Query query={Advertisers}>
            {({loading, error, data}) =>{
            if(loading) return null;
            if(error) return `${error}`;

            return (
                    <div>
                        <div id="buyers">
                            <select name="" id="" onChange={(e)=>{this.setBuyer(e, data.advertisers)}} defaultValue={null}>
                                <option value={null} >Select Buyer</option>
                                {data.advertisers.map((a,i)=><option value={i} key={i}>{a.buyer}</option>)}
                            </select>
                        </div>
                        <div id="buyer-campaigns">
                            <div className="active-campaigns">
                                <h4 className="text-center">Active Campaigns</h4>
                                <ul className="list-group">
                                {this.state.campaigns?this.state.campaigns.map((campaign,i)=>{
                                    return (
                                        <li className="list-group-item" key={campaign.buyer_campaign}>{campaign.buyer_campaign} 
                                                <button onClick={(e)=>{this.removeCampaign(i)}} className="btn btn-sm btn-danger"><i className="fa fa-close"/></button>
                                        </li>
                                    )
                                }):<h1>Nothing to show</h1>}
                                </ul>
                            </div>
                            <BuyerCampaigns campaigns={this.state.buyer_index?data.advertisers[this.state.buyer_index].campaigns:[]} addCampaign={this.addCampaign} active_campaigns={this.state.campaigns}/>
                        </div>
                    </div>
                        )}
                }
            </Query>  
        )
        }else{
        return (
        <Query query={AdvertiserCampaigns} variables={{name:this.state.buyer}}  >
                {({loading, error, data}) =>{
                if(loading) return null;
                if(error) return `${error}`;

                return (
          
                        <div id="buyer-campaigns">
                            <div className="active-campaigns">
                                <h4 className="text-center">Active Campaigns</h4>
                                <ul className="list-group">
                                {this.state.campaigns.map((campaign,i)=>{
                                    return (
                                        <li className="list-group-item" key={campaign.buyer_campaign}>{campaign.buyer_campaign} 
                                                <button onClick={(e)=>{this.removeCampaign(i)}} className="btn btn-sm btn-danger"><i className="fa fa-close"/></button>
                                        </li>
                                    )
                                })}
                                </ul>
                            </div>
                            <BuyerCampaigns campaigns={data.advertiserbyName?data.advertiserbyName[0].campaigns:null} addCampaign={this.addCampaign} active_campaigns={this.state.campaigns}/>
                        </div>
                            )}
                    }
        </Query>  
        )
                }
    }

    //middleware for mutation of forecast (check types before they go out)
    async mutateForecast(mutation){
        let schedule = {...this.state};
        console.log(schedule);
        let start = new Date(schedule.start_time);
        let end = new Date(schedule.end_time);
        schedule.start_time_hour = start.getHours();
        schedule.start_time_minute = start.getMinutes();
        schedule.end_time_hour = end.getHours();
        schedule.end_time_minute = end.getMinutes();
        schedule.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        console.log(schedule);
        //remove unwanted keys
        delete schedule.buyer_index;
        let campaign = this.props.campaign_id;
        let results=await  mutation({variables:{campaign_id:campaign, schedule:schedule}});
        console.log(results);
        // forecastMuation({variables:{campaign_id:this.props.campaign_id, 
        //     schedule:{...this.state} } } )
    }

    async refresh(cache, data){
        console.log(cache, data);
        const { campaign } = cache.readQuery({ query: CAMPAIGN, variables:{_id:this.props.campaign_id}});
        console.log(campaign);
        // cache.writeQuery({
        //     query: CAMPAIGN,
        //     data: { campaign_by_id: {campaign}campaign_by_id.buyerSchedules.concat([data.updateBuyerSchedule]) },
        // });
    }


    render(){
        return (
            <Mutation mutation={BuyerForecastsMutation}   update={this.refresh}>
            {(forecastMuation, {data}) => (

                  <form className="forecast row" onSubmit={e => {e.preventDefault(); 
                                                                 this.mutateForecast(forecastMuation);
                                                                }}>
                  <div className="col-12">
                      <div className="card">
                          <div className="card-header row no-gutters align-items-center">
                              <div className="col-6">
                                  <h3>{this.state.name}</h3>
                              </div>
                             
                              <div className="col-6 text-right">
                                  <button className="btn btn-warning" onClick={this.props.closeForecast}>
                                      <i className="fa fa-close"></i>
                                  </button>
                                  <button type="submit" className="btn btn-success"><i className="fa fa-save"/></button> 
                              </div>
                          </div>
  
                          <div className="card-body">
                                  <div className="forecast-setup-form">
                                        <div className="settings">
                                              {/* heading */}
                                            <h3 className="heading">Settings</h3>
                                            {/* begin form */}
                                            <div className="form">
                                                <div className="form-group">
                                                    <label htmlFor="name">Forecast Name</label>
                                                    <input className="form-control" type="text"
                                                        name="name"
                                                        placeholder={this.state.name? this.state.name : "Enter Name For Forecast"} 
                                                        onChange={this.handleForecastUpdate} />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="total_calls">Calls Forecasted</label>
                                                    <input className="form-control" type="number"
                                                        name="total_calls"
                                                        placeholder={this.state.total_calls? this.state.total_calls : "Forecasted Calls"} 
                                                        onChange={this.handleForecastUpdate} />
                                                </div>
                                                <div className="form-group">
                                                    <div className="form-check tex-align-center">
                                                        <input className="form-check-input" 
                                                                type="radio" 
                                                                name="calls_tracked" 
                                                                onChange={this.handleForecastUpdate}
                                                                value="paid" 
                                                                defaultChecked={this.state.calls_tracked === 'Paid' ? true : false}
                                                                />
                                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                                            Paid
                                                        </label>
                                                    </div>  
                                                    <div className="form-check text-align-center">
                                                        <input className="form-check-input" 
                                                                type="radio" 
                                                                name="calls_tracked" 
                                                                id="Raw"
                                                                value="raw"
                                                                defaultChecked={this.state.calls_tracked === 'Raw' ? true : false}
                                                                onChange={this.handleForecastUpdate} 
                                                                />
                                                        <label className="form-check-label" htmlFor="raw">
                                                            Raw
                                                        </label>
                                                    </div>  
                                                </div>
                                                <div className="form-group hours">
                                                        <div className="time">
                                                            <label htmlFor="opens">Opens </label>
                                                            <TimePicker defaultValue={this.state.start_time ? moment(this.state.start_time) : null} 
                                                                        showSecond={false} minuteStep={30} 
                                                                        name="opens" use12Hours
                                                                        onChange={(e)=>{this.handleForecastUpdate(e, 'start_time')}}
                                                                        />
                                                        </div>
                                                        <div className="time">
                                                            <label htmlFor="closes">Closes</label>
                                                            <TimePicker defaultValue={this.state.end_time ? moment(this.state.end_time) : null} 
                                                            showSecond={false} minuteStep={30} 
                                                            name="closes" use12Hours
                                                            onChange={(e)=>{this.handleForecastUpdate(e, 'end_time')}}
                                                            />
                                                        </div>
                                                </div>
                                                <div className="form-group status">
                                                    <div>
                                                        <input className="form-check-input" 
                                                                    type="checkbox" 
                                                                    name="active" 
                                                                    id="active"
                                                                    defaultChecked={this.state.active}
                                                                    onChange={this.handleForecastUpdate} 
                                                                    />
                                                        <label className="form-check-label" htmlFor="active">
                                                            active
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input className="form-check-input" 
                                                                    type="checkbox" 
                                                                    name="email" 
                                                                    id="email"
                                                                    defaultChecked={this.state.email}
                                                                    onChange={this.handleForecastUpdate} 
                                                                    />
                                                        <label className="form-check-label" htmlFor="email">
                                                            email
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                                                    
                                        <div className="schedule">
                                            <h3>Schedule</h3>
                                            <div className="col-12 text-center">
                                                <ul className="list-group line-items">
                                                    <li className="line-item list-group-item heading">
                                                        <span>Ending Block</span>
                                                        <h5>Forecasted</h5>
                                                    </li>
                                                    {this.state.line_items?this.state.line_items.map((d,i)=>{
                                                        return (
                                                            <li className="line-item list-group-item" key={d.end_time}> 
                                                                <span>
                                                                {d.end_time}
                                                                </span>
                                                                <input type="number" placeholder={d.forecasted} htmlFor="forecasted" name="forecasted"  onChange={(e)=>this.handleScheduleChange(e,i,d)}></input>
                                                            </li>
                                                        )
                                                    }):null}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="buyer-setup">
                                            <hr/>
                                            <h3 className="text-center">Buyer Setup</h3>
                                            {this.campaigns()}
                                        </div>
                                  </div>
                          </div>
                      </div>
                  </div>

              </form>


            )}
          
        </Mutation>
        )
    }
}


export default BuyerForecast