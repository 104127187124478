import React, {Component}  from 'react';


class Buyers extends Component {

    constructor(props){
        super(props);
        this.state = {
            buyers:this.props.buyers
        }
        this.getBuyers = this.getBuyers.bind(this);
    }

    getBuyers(buyers){
        if(buyers){
            return(
                <table className="table">
                <thead>
                    <tr>
                        <td>Buyer</td>
                        <td>Campaigns</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        buyers.map((buyer)=>{
                            return(
                                <tr key={buyer.buyer}>
                                    <td>{buyer.buyer}</td>
                                    <td>{this.getBuyerCampaigns(buyer.campaigns)}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
                </table>
            )
        }else{
            return(
                <div>No Buyers here</div>
            )
        }
       
    }

    render(){
        return(
            <div className="col-7">

            <div className="card">
                <div className="card-header">
                    <h3>Buyers</h3> 
                </div>
                <div className="card-body">
                    <div className="buyers">
                        {this.getBuyers(this.state.buyers)}
                    </div>
                    
                </div>
            </div>
        </div>
        );
    }

}

export default Buyers;