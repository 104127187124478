import React, { Component } from 'react';
import {Query} from 'react-apollo';
import gql from "graphql-tag";
import {Link} from 'react-router-dom';


class CampaignList extends Component {
  query(){
       return  gql`{campaigns{
            _id
            name
            creatives
            buyers{
                buyer
                campaigns
            }
            publishers{
                publisher
                campaigns
            }
            sync_forecasts
        }}
    `;
  }

  getTable(data){
     return( 
   <table className="table table-bordered">
        {/* thead */}
        <thead>
            <tr>
                <th>Campaign Name</th>
                <th>Syncing Forecasts</th>
                <th className="text-right">Actions</th>
            </tr>
        </thead>
        <tbody>
        {data.campaigns.map(d =>{
                            return(<tr key={d._id}>
                                <td>{d.name.toUpperCase()}</td>
                                <td>{d.sync_forecasts.toString()}</td>
                                <td className="text-right">
                                    <Link className="btn" to={`/campaigns/${d._id}`}><i className="fa fa-edit fa-2x"></i></Link>
                                </td>
                            </tr>);
                        })}
        </tbody>
    </table>)
  }

  render() {
    return (
       <div className="container-fluid">
        <div className="row justify-content-center">
                <div className="col-8">
                 <div className="card">
                    <div className="card-header">
                        <h2>Campaigns</h2>
                    </div>
                    <div className="card-body">
                        <Query query={this.query()} >
                            {({loading, error, data})=>{
                                if(loading) return  <p>Loading...</p>;
                                if(error) return <p>Error </p>;
                                return this.getTable(data);
                            }}
                        </Query>
                    </div>
                 </div>
                   
                </div>
        </div>
           
       </div> 
    );
  }
}

export default CampaignList;
