import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {withUser} from '../context/userContext';
import './sidebar.css';

class SideBar extends Component {
  constructor(props){

    super(props);
    this.state={
      show:false
    }
    this.show = this.show.bind(this);
  }

  show(e){
    e.preventDefault();
    this.setState({show:!this.state.show});
  }

  render() {
    if(this.props.authenticated){
    return (
        <div className={`${this.props.className}`}>
             <div className="row justify-content-center sidebar-heading">
                <h2>TogetherHealth</h2>
             </div>
             <div className="sidebar-nav" onClick={this.show}>
                <ul className={`sidebar-links ${this.state.show?'show':''}`}>
                    <li><Link to="/call-centers"><i className="fa fa-phone fa-lg"></i>Call Centers</Link></li>
                    <li><Link to="/campaigns"><i className="fa fa-building-o fa-lg"></i> Campaigns</Link></li>
                    <li><Link to="/forecasts"><i className="fa fa-cloud fa-lg"></i> Forecasts</Link></li>
                    <li><Link to="/media"><i className="fa fa-file-video-o fa-lg"></i> Media</Link></li>
                    <li><Link to="/reports"><i className="fa fa-bar-chart fa-lg"></i> Reports</Link></li>
                    <li><Link to="/tools/add-logs"><i className="fa  fa-wrench fa-lg"></i>Tools    </Link></li> 
                </ul>
                <div className="sidebar-link with-sections">
                  <div className="sidebar-section heading" >
                        <div><Link to="/prelogs"><i className="fa fa-file-video-o fa-lg"></i> Airings</Link></div>
                        <div><button><i className="fa fa-arrow-down"></i></button></div>
                  </div>
                  <div className="sidebar-section sub-items">
                    <div><Link to="/prelogs"><i className="fa fa-file-video-o fa-lg"></i> Airings</Link></div>
                  </div>
                  
                </div>
             </div>
        </div>
    );
  }else{
    return null; 
  }
}
}

export default withUser(SideBar);
