import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
//create context for user auth - holds user logged in state and user info
export const UserContext = React.createContext();
//higher order component -  Takes in a component and passes UserContext
export function withUser(Component){
    return function AuthComponent(props){
        return(
            <UserContext.Consumer>
                    {({token, login, logout, authenticated}) => (

                         <Component {...props} login={login} logout={logout} authenticated={authenticated} token={token}/>
                    )}
            </UserContext.Consumer>
        )
    }
};
//the provider
export class UserProvider extends Component{
    constructor(props){
        super(props);
        this.state = {
            authenticated:localStorage.getItem('tgh-token')?true:false,
            token:localStorage.getItem('tgh-token')
        }
    }
    componentDidMount(){
        this.checkLoggedIn();
    }
    checkLoggedIn = ()=>{
        //check if we are logged in by getting item from local storage
        let token = localStorage.getItem('tgh-token');
        //if logged in then we store that user info as variable
        if(token !== null){
            // let userInfo = JSON.parse(localStorage.getItem('user_info'));
            this.setState({authenticated:true});
        }else{
            this.logout()
        }
    
    }
    login = (success, data) =>{
        if(success){
            //set the token
            localStorage.setItem('tgh-token', data.token);
            this.setState({
                user:{user_info:data.userInfo},
                authenticated:true,
                token:data.token
            });
        }else{
            this.setState({
                authenticated:false,
                token:null
            });
        }
    }
    logout = (client) =>{
        localStorage.removeItem('tgh-token');
        this.setState({authenticated:false, token:null}, ()=>{
            if(client){
                client.resetStore();
            }
        });
    }
    render() {
        const {children} = this.props;
        return(
            <UserContext.Provider value={{
                user:this.state.user,
                authenticated:this.state.authenticated,
                login:this.login,
                logout:this.logout,
                token:this.state.token
            }}>
             {children}
            </UserContext.Provider>
        )
    }
}

export default withRouter(UserProvider);
export const UserConsumer = UserContext.Consumer;