import React, {Component} from 'react';
import ForecastCard from './forecast-card';

class ForecastContainer extends Component {

    render(){
        if(this.props.forecasts){
            return (
                <div className="forecast-container"> 
                    {this.props.forecasts.map((d)=><ForecastCard data={d} key={d._id}></ForecastCard>)}
                </div>
            )
        }else{
            return null;
        }
    }
    
}


export default ForecastContainer;