import React, {Component} from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import XLSX from 'xlsx';
import TotalCards from '../total-cards/total-cards.jsx';
import PrivateRoute from '../private-route/private-route';


const SPEND_QUERY = gql`
    query spendByNetwork($start:Date!, $end:Date!, $creatives:[String]){
        spendByNetwork(start:$start, end:$end, creatives:$creatives){
            _id
            station
            creatives
            phone
            spend
            total_calls
            average_spend_per_airing
            average_calls_per_airing
            estimated_cprc
            current_spend
            current_cprc
            note
            collect_calls_from
            collect_calls_until
        }
    }
`

class SpendReport extends Component {
    constructor(props){
        super(props);

        this.state = {
            start:moment().startOf('day'), 
            end:moment().endOf('day'),
            creatives:[],
            creative_total:[]
        }
        this.columns = this.columns.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.exportReport = this.exportReport.bind(this);
        this.selectFields = this.selectFields.bind(this);
    }

    selectFields(){
        
    }


    handleChange(e){
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;
        if(name === "creatives" ){

            value = target.value.split(',').map(d=>d.trim());

        }
        this.setState({[name]:value}, ()=>{
            console.log(this.state);
        });
    }
    handleDateChange(dateName, value){
        if(dateName.indexOf('end') > -1){
            this.setState({[dateName]:moment(value).endOf('day')})
        }else{
            this.setState({[dateName]:value});
        }
    }
    exportReport(e){
        e.preventDefault();
        let data = this.selectTable.getResolvedState();
        console.log(data);
        let columns = data.columns;
        
        let data_to_export = data.sortedData.map((d)=>{
            let object_to_build = {};

            for(let i in columns){

                object_to_build[columns[i].Header] = d[columns[i].accessor];
                if(columns[i].Header === "Time"){
                    object_to_build[columns[i].Header ]= moment(d[columns[i].accessor]).format("hh:mm a");
                }
                // if(columns[i].Header === "Spend" || columns[i].Header === "Current Spend"){
                //     object_to_build[columns[i].Header ] = d[columns[i].accessor] ? d[columns[i].accessor].toLocaleString('en-US', {style:"currency", currency:"USD"}) : "$0.00";
                // }
                // if(columns[i].Header === "Creatives"){
                    // object_to_build[columns[i].Header] = d[columns[i].accessor] ? d[columns[i].accesor].join('/') : "";
                // }
            }
            return object_to_build;
        });
        console.log(data_to_export);

        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(data_to_export);
        console.log(worksheet);
        XLSX.utils.book_append_sheet(workbook, worksheet, "SheetJS");
        XLSX.writeFile(workbook, `${this.state.start.format('MM.DD.YY')}-${this.state.end.format('MM.DD.YY')}-SPEND-REPORT.xlsx`)
    }

    columns(){
        return  [
         {
              Header:'station',
              accessor: 'station',
          },
          {
              Header:'Creatives',
              accessor: 'creatives',
          },
          {
              Header:'Phone',
              accessor:'phone'
          },
          {
              Header:'Spend',
              accessor:'spend',
              Cell:props=>props.value?props.value.toLocaleString('en-us',{style:"currency", currency:"USD"} ):"$0.00"
          },
          {
              Header:'Calls',
              accessor:'total_calls',
              Cell:props=>props.value || 0
          },
          {
              Header:"Spend(AVG)",
              accessor:"average_spend_per_airing",
              Cell:props=>props.value?props.value.toLocaleString('en-us',{style:"currency", currency:"USD"} ):"$0.00"
          },
          {
              Header:"Calls(AVG)",
              accessor:"average_calls_per_airing",
              Cell:props=>Math.round(props.value) || 0
          },
          {
              Header:"Current Spend",
              accessor:"current_spend",
              Cell:props=>props.value?props.value.toLocaleString('en-us',{style:"currency", currency:"USD"} ):"$0.00"
          },
          {
              Header:"Current CPRC",
              accessor:"current_cprc",
              Cell:props=>props.value?props.value.toLocaleString('en-us',{style:"currency", currency:"USD"} ):"$0.00"
          },
          {
              Header:"Note",
              accessor:"note"
          },
          {
              Header:"From",
              accessor:"collect_calls_from",
              Cell:props=>props.value?moment(props.value).format("MM/DD/YYYY"):""
          },
          {
              Header:"To",
              accessor:"collect_calls_until",
              Cell:props=>props.value?moment(props.value).format("MM/DD/YYYY"): ""
          }
        ];
    }

   


    render(){
        return (
            <div>
                <div className="report-parameters">
                        <h2 className="header">Spend Report Parameters</h2>
                        <div className="fields">
                            <div className="form-group">
                                    <label htmlFor="start">Start Date</label>
                                    <DatePicker selected={this.state.start}
                                            onSelect={(date)=>{this.handleDateChange("start", date)}}
                                            className="form-control"
                                    />
                            </div>
                            <div className="form-group">
                                    <label htmlFor="end">End Date</label>
                                    <DatePicker selected={this.state.end}
                                            onSelect={(date)=>{this.handleDateChange("end", date)}}
                                            className="form-control"
                                    />
                            </div>
                            <div className="form-group">
                                <label htmlFor="creatives">Creatives</label>
                                <input className="form-control"
                                        type="text"
                                        placeholder="Creatives"
                                        name="creatives"
                                        onChange={this.handleChange}/>
                            </div>
                        </div>
                      
                </div> 
                <Query query={SPEND_QUERY} variables={this.state} >
                {({loading, error, data}) =>{
                            if(loading) return `${'Loading'}`;
                            if(error) return `${error}`;
                            return(
                                <div>
                                {/* <h2>Spend Report</h2> */}
                                <TotalCards data={data.spendByNetwork} aggregateby={[{title:"Creatives ", 
                                                                    accessor:"creatives", 
                                                                    fields:[{title:"Spend", 
                                                                            accessor:"current_spend", 
                                                                            type:"Currency", 
                                                                            aggregate:"Sum"},
                                                                            {
                                                                                title:"Calls",
                                                                                accessor:"total_calls",
                                                                                type:"Number",
                                                                                aggregate:"Sum"
                                                                            },
                                                                            {
                                                                                title:"CPRC",
                                                                                accessor:["Spend", "Calls"],
                                                                                type:"Currency",
                                                                                aggregate:"Divide"
                                                                            }
                                                                    ]}]}></TotalCards>
                                <div className="card report-card">
                                    <div className="card-header">
                                        <h5>Spend Report </h5>
                                        <div className="actions">
                                            <button className="btn" onClick={this.exportReport}><i className="fa fa-arrow-down fa-2x"/></button>
                                            <button className="btn" onClick={this.selectFields}><i className="fa fa-cog fa-2x"/></button> 
                                        </div>
                                    </div>
                                                                    
                                
                                    <div className="card-body">
                                        {/* {this.showTotals()} */}
                                        <div className="table-wrapper">
                                    
                                            <ReactTable className="table"  data={data.spendByNetwork} columns={this.columns()} filterable ref={(r) => {
                                                    this.selectTable = r;
                                                }}>
                                            </ReactTable>
                                        </div>
                    
                    
                                    </div>
                                </div>
                                </div>
                                )   
                        }}
                </Query>
            </div>




            
        )
    }
}

export default PrivateRoute({requireLoggedIn:true})(SpendReport);