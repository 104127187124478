import React , {Component} from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import moment from 'moment';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import privateRoute from '../private-route/private-route';
import 'react-table/react-table.css'
import './prelogs.css';

const PRELOG_QUERY =  gql `query prelogsByDate($start:Date, $end:Date){
    prelogsByDate(start:$start, end:$end){
        date_aired
        time_stamp
        station
        phone
        spend
        creative
        program_aired
        paid
        revenue
        rpc
        cprc
        raw
        raw_15
        projected_calls
        market
    }
}`;

class Prelogs extends Component{
    constructor(props){
        super(props)
        this.state = {
            start_date:moment().startOf('day'),
            end_date:moment().endOf('day'),
            start:moment().startOf('day'),
            end:moment().endOf('day'),
            columns:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.defaultFilter = this.defaultFilter.bind(this);
    }

    componentDidMount(){
        const columns = [{
            Header: 'Date',
            accessor: 'time_stamp',
            Cell:props => moment(props.value).format("MM/DD/YYYY")
          },{
              Header: 'Time',
              accessor: 'time_stamp',
              Cell: props => moment(props.value).format('hh:mm a')
          },{
              Header:'Creative',
              accessor: 'creative',
              filterMethod:this.defaultFilter
              
          },{
              Header:'Station',
              accessor: 'station',
              filterMethod:this.defaultFilter
          },
            {Header:"Phone",
            accessor:"phone",
            filterMethod:this.defaultFilter
            },
          {
              Header:'Program',
              accessor:'program_aired',
              filterMethod:this.defaultFilter
          },
          {
              Header:'Spend',
              accessor:'spend',
              Cell:props=>props.value.toLocaleString('en-us', {style:"currency", currency:"USD"})
          },
          {
              Header:'Calls',
              accessor:'raw',
              Cell:props=>props.value || 0
          },
          {
              Header:"Paid Calls",
              accessor:"publisher_paid_calls",
              Cell:props=>props.value || 0
          },
          {
              Header:"CPRC",
              accessor:"cprc",
              Cell:props=>props.value?props.value.toLocaleString('en-us',{style:"currency", currency:"USD"} ):"$0.00"
          },
          {
            Header:"Projected",
            accessor:"projected_calls",
            Cell:props=>props.value || 0
          },
          {
              Header:"Market",
              accessor:"market",
              filterMethod:this.defaultFilter
          }
        ];
        this.setState({columns:columns});
    }

    handleChange(e){
        let target = e.target;
        let name = target.name;
        let value = target.value;
        console.log(name, value);
        this.setState({[name]:value});
    }

    handleDateChange(dateName, value){
        console.log(value);
        this.setState({[dateName]:value, [`${dateName.replace('_date','')}`]:value});
    }
    
   
    defaultFilter(filter, row){
        let value = filter.value.toLowerCase();
        let id = filter.id;
        let row_value = row[id].toLowerCase();
        return row_value.indexOf(value) > -1;
    }
    render(){
        return(
            <div>
                     <div className="report-parameters">
                        <h2 className="header">Logs </h2>
                        <div className="fields">
                                <div className="form-group">
                                    <label htmlFor="start">Start</label>
                                    <DatePicker selected={this.state.start_date}
                                                onSelect={(date)=>{this.handleDateChange("start_date", date)}}
                                                className="form-control"
                                        />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="end">End</label>
                                    <DatePicker selected={this.state.end_date}
                                                onSelect={(date)=>{this.handleDateChange("end_date", date)}}
                                                className="form-control"
                                        />
                                </div>
                        </div>
                    </div>
                <div className="card report-card">
                    <div className="card-header">
                        <h2> Logs </h2>
                    </div>
                    <div className="card-body">
                            <Query query={PRELOG_QUERY} variables={this.state}>
                                {({loading, error, data}) =>{
                                    if(loading) return `${'Loading'}`;
                                    if(error) return `${error}`;
                                    return(
                                            <div className="table-wrapper">
                                                <ReactTable data={data.prelogsByDate} columns={this.state.columns} filterable >
                                                </ReactTable>
                                            </div>
                                    );   
                                }}
                            </Query>
                    </div>
                </div>
            </div>
        )
    }
}


export default privateRoute({requireLoggedIn:true})(Prelogs);