import React, {Component} from 'react';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import BuyerForecast from './BuyerForecast';



class BuyerForecasts extends Component{
    constructor(props){
        super(props);

        this.state = {
            selected_forecast:{},
            show_forecast:false,
            forecasts:this.props.forecasts,
            campaign_id:this.props.campaign_id,
            buyer_campaigns:[],
            active_campaigns:[],
            forecastUpdate:{},
            line_items:[],
            new_forecast:false
        }

        this.forecastList = this.forecastList.bind(this);
        this.setForecast = this.setForecast.bind(this);
        this.closeForecast = this.closeForecast.bind(this);
        this.newForecast = this.newForecast.bind(this);
    }
    //temp component will mount()
    componentDidMount(){
        // this.setState({show_forecast:true, selected_forecast:this.props.forecasts[0]});
    }
    setForecast(index){
        this.setState({selected_forecast:this.props.forecasts[index], show_forecast:true});
    }

    newForecast(){
        this.setState({selected_forecast:{}, show_forecast:true, new_forecast:true});
    }

    closeForecast(){
        console.log('closing forecast');
        this.setState({
            show_forecast:false,
            forecastUpdate:{},
            selected_forecast:{}
        })
    }

    forecastList(){
        return(
            <div>
            <table className="table">
                <thead>
                    <tr>
                        <td>Forecast Name</td>
                        <td>Buyer</td>
                        <td>Campaigns</td>
                        <td>Hours</td>
                        <td>Target</td>
                        <td>Active</td>
                        <td>Email</td>
                        <td>Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {this.state.forecasts.map((d,i)=>{
                        console.log(d);
                        let start_hour = moment(d.start_time).format("hh:mm a");
                        let end_hour = moment(d.end_time).format('hh:mm a');

                        return (
                            <tr key={d._id}>
                                <td>{d.name}</td>
                                <td>{d.buyer}</td>
                                <td className="buyer-campaigns">{
                                   d.campaigns?d.campaigns.map(k=><span className="badge badge-primary" key={k.buyer_campaign}>{k.buyer_campaign}</span>):""
                                }</td>
                                <td>{`${start_hour} - ${end_hour}`}</td>
                                <td>{d.total_calls}</td>
                                <td><i className={`fa ${d.active ? 'fa-check green' : 'fa-close red'}  fa-lg`}/></td>
                                <td><i className={`fa ${d.email ? 'fa-check green' : 'fa-close red'}  fa-lg`}/></td>
                                <td>
                                    <button className="Edit btn btn-sm btn-warning" onClick={()=>{this.setForecast(i)}}>
                                    <i className="fa fa-edit"/>
                                    </button>
                                    <button className="Edit btn btn-sm btn-danger" onClick={()=>{this.setForecast(i)}}>
                                    <i className="fa fa-close"/>
                                    </button>
                                </td>
                                
                            </tr>
                        )

                    })}
                </tbody>
            </table>
            <div className="actions">
                <button className="btn btn-success" onClick={this.newForecast}> <i className="fa fa-plus"></i></button>
            </div>
            </div>
        )
    }

    render(){
        if(this.state.show_forecast){
            return <BuyerForecast  forecast={this.state.selected_forecast} campaign_id={this.state.campaign_id} closeForecast={this.closeForecast} new={this.state.new_forecast}></BuyerForecast>
        }else{
           return this.forecastList();
           
        }
    }
}

export default BuyerForecasts;