import React, { Component } from 'react';
import NavBar from './navbar/nav';
import Main from  './main.jsx';
import './App.css';
import SideBar from './sidebar/sidebar';
import {UserConsumer} from './context/userContext.jsx';


class App extends Component {
  render() {
    return (
      <UserConsumer>
       {({login, user, authenticated, logout})=>(

          <div className="wrapper">
            <SideBar className="main-nav"/>
            <div className="content">
              <Main className="content"/>
            </div>
          </div>

          
         )}
      </UserConsumer>
    );
  }
}

export default App;
