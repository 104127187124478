import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import React from 'react';
import cubejs from '@cubejs-client/core';
import { QueryRenderer } from '@cubejs-client/react';
import { Spin, Table } from 'antd';
import ReactTable from "react-table";
import {DateTime } from "luxon";
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line
} from "recharts";

const CartesianChart = ({ resultSet, children, ChartComponent }) => (
  <ResponsiveContainer width="100%" height={350}>
    <ChartComponent data={resultSet.chartPivot()}>
      <XAxis dataKey="x" />
      <YAxis />
      <CartesianGrid />
      { children }
      <Legend />
      <Tooltip />
    </ChartComponent>
  </ResponsiveContainer>
)

const colors = ['#FF6492', '#141446', '#7A77FF'];

const lineRender = ({ resultSet }) => (
  <CartesianChart resultSet={resultSet} ChartComponent={LineChart}>
      {resultSet.seriesNames().map((series, i) => (
        <Line
          key={series.key}
          stackId="a"
          dataKey={series.key}
          name={series.title}
          stroke={colors[i]}
        />
      ))}
  </CartesianChart>
);
const transform = (resultSet)=>{
    let to_return = [];
    let data = resultSet.loadResponse.data;
    data.map((d, i)=>{
        // if(d.startTimeUtc){
        //     d.startTimeUtc = DateTime.fromSQL(d.startTimeUtc).toFormat("MM/dd/YYYY");
        // }
        to_return.push({
            key:i +1,
            ...d
        });
    });

    console.log(to_return);
    return to_return;
    // {
    //     key: '1',
    //     name: 'Mike',
    //     age: 32,
    //     address: '10 Downing Street',
    //   },
    //   {
    //     key: '2',
    //     name: 'John',
    //     age: 42,
    //     address: '10 Downing Street',
    //   },
};

const columns = (resultSet) =>{
    let col = [];
    let data = resultSet.loadResponse.data[0];
    // {
    //     title: 'Name',
    //     dataIndex: 'name',
    //     key: 'name',
    //   },
     for(let i in data){
        if(i.indexOf("startTimeUtc") === -1){
        col.push(
            {
                dataIndex:i,
                key:i,
                title:i.split(".")[1],
                sorter: (a, b, sortDirections) => {
                    let multiplier = sortDirections === "descend"? -1 : 1;
                    if(a[i] > b[i]){
                        return 1 * multiplier;
                    }
                    if(a[i]< b[i]){
                        return -1 * multiplier;
                    }
                    return 0;
                },
                sortDirections: ['descend', 'ascend']
            }
        )
        }else{
            col.unshift({
                dataIndex:i,
                key:i,
                title:"Date",
                sorter: (a, b, sortDirections) => {
                    let date_a = parseInt(new Date(a[i]).valueOf());
                    let date_b = parseInt(new Date(b[i]).valueOf());
                    return date_a - date_b;
                },
                sortDirections: ['descend', 'ascend'],
                render: date => {
                    return DateTime.fromJSDate(new Date(date)).toFormat("MM/dd/yyyy")
                }
            })
        }
     }
     console.log(col);
    return col;

    // resultSet.data.map()
};

const tableRender = ({resultSet}) => (
    <Table dataSource={transform(resultSet)}  columns={columns(resultSet)} size="small" pagination={{ pageSize: 50 }} scroll={{ y:500 }}>

    </Table>
);

const API_URL = "http://localhost:4000"; // change to your actual endpoint

const cubejsApi = cubejs(
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NzQwMTA2NjIsImV4cCI6MTU3NDA5NzA2Mn0.8PzpFLhMfvre6GE74Js4aoedtA5o8FYExrSZt16i0QI",
  { apiUrl: API_URL + "/cubejs-api/v1" }
);

const renderChart = (Component) => ({ resultSet, error }) => (
  (resultSet && <Component resultSet={resultSet} />) ||
  (error && error.toString()) || 
  (<Spin />)
)

const ChartRenderer = () => <QueryRenderer
  query={{
    "measures": [
      "Transaction.count"
    ],
    "dimensions":["Transaction.advertiserName", "Transaction.advertiserCampaignName"],

    "timeDimensions": [
      {
        "dimension": "Transaction.startTimeUtc",
        "granularity": "day",
        "dateRange": "Last 7 days"
      }
    ],
    "renewQuery":true,
    "order":{"Transaction.advertiserName":"asc", "Transaction.advertiserCampaignName":"asc"},
    "timezone":"America/New_York",
    "filters": []
  }}
  cubejsApi={cubejsApi}
  render={renderChart(tableRender)}
/>;

export default ChartRenderer;


