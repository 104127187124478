import React, { Component } from 'react';
import {Line, Bar} from 'react-chartjs-2';
import moment from  'moment';
import RandomColor from 'randomcolor';

class RPCChart extends Component {
  constructor(props){
    super(props);
    this.state = {
        x_accessor:props.x_accesor?props.x_accesor:null,
        y_data:props.y_data?props.y_data:null,
        data:this.props.data,
        timeStamp:new Date().valueOf(),
        data:[],
        options:{},
        chart_data:{data_sets:[]},
        colors:["#878C8F", "#700548",  "#2978A0", "#315659", "#3C3744","#95E06C"]
    }

    this.combine = this.combine.bind(this);
    this.chart_type = this.chart_type.bind(this);
  }

  combine(){
    let self = this;
    let data_sets = this.props.data.reduce((a, b,i)=>{

        a = a.concat({label:b[this.props.x_accesor],
                        showLine: true,
                        fill: false,
                        borderColor:this.state.colors[i],
                        backgroundColor:this.state.colors[i],
                      data:b.line_items.reduce((k,y)=>{return k = k.concat({y:y[this.props.y_data], x:moment(y["end_time"], "hh:mm a").toDate()})},[])
                    });
        return a;
    },[]);
    let chart_data = {
        type: "line",
        datasets:data_sets,
        options: {
            animation: {
                easing: 'easeInOutQuad',
                duration: 520
            },
            elements: {
                line: {
                    tension: 0.4
                }
            },
            scales: {
                xAxes: [{
                    title:"Half Hour Block",
                    type:'time',
                    gridLines:{
                        lineWidth: 2
                    },
                    time:{
                        unit:"minute"
                    },
                    ticks:{
                        source:'data'
                    }
                }],
                yAxes:[{
                    ticks:{
                    beginAtZero:true,
                    callback:function(value,index, values){
                        if(self.props.format){
                            if(self.props.format === "percent")
                            {
                                return value.toLocaleString('en-US', {style:self.props.format})
                            }
                        }
                        return value.toLocaleString('en-US',{style:'currency',  currency:"USD"});
                    }}
                }]
              
            },
            tooltips:{
                intersect:false,
                backgroundColor:"black",
                callbacks:{
                    label: function(tooltipItem, data){
                        let style = {}
                        if(self.props.format){
                            if(self.props.format === "currency"){
                                style = {style:'currency', currency:"USD"}
                            }else{
                                style = {style:"percent"};
                            }
                        }
                        return `${new Date(tooltipItem.xLabel).toLocaleTimeString('en-US')}  - ${tooltipItem.yLabel.toLocaleString('en-US', style)}`
                    },
                    title: function(tooltipItem, data){
                        let title = data.datasets[tooltipItem[0].datasetIndex].label;
                        return `${title} - Half Hour Block - ${moment(tooltipItem[0].xLabel).format("hh:mm A")}`;
                        // return  moment(tooltipItem.xLabel).format("hh:mm A");
                        
                    }
                }
            },
            legend:{
                position:'bottom'
            },
            title:{
                display:true,
                text:`${self.props.title} By Buyer - By Half Hour Block`
            }
        }
    }
    this.setState({chart_data:chart_data, options:chart_data.options});
  }

  //build chart
  componentDidMount(){
    this.combine();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timeStamp !== nextProps.timeStamp) {
        this.combine();
    }
    return true;
}

chart_type(){
    if(this.props.type === "bar"){
        return <Bar data={this.state.chart_data} options={this.state.options}></Bar>
    }else{
        return <Line data={this.state.chart_data} options={this.state.options}></Line>
    }
}



  render() {

        return (
        <div>
           { this.chart_type()}
        </div> 
        )
        
  }
}

export default RPCChart;
