import React, {Component} from 'react'; 
import hoistNonReactStatics from 'hoist-non-react-statics';
import {withUser} from '../context/userContext';

// privateRoute is a function...
const PrivateRoute = ({
    // ...that takes optional boolean parameters...
    requireLoggedIn = false,
    requireOnboarded = false,
    requireWaitlisted = false
  // ...and returns a function that takes a component...
  } = {}) => WrappedComponent => {
    class Private extends Component {
      componentDidMount() {
        const {
         authenticated:authenticated
        } = this.props;
        if (requireLoggedIn && !authenticated) {
          this.props.history.push(`/login/?return=${this.props.location.pathname?this.props.location.pathname:'/'}`);

        } 
      }
      componentDidUpdate(a, b ){
        if(a.authenticated != this.props.authenticated){
          this.props.history.push(`/login/?return=${this.props.location.pathname?this.props.location.pathname:'/'}`);
        }
      }
  
      render() {
        return (
          <WrappedComponent {...this.props} />
        )
      }
    }
  
    Private.displayName = `Private(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`
  
    // ...and returns a new component wrapping the parameter component
    return hoistNonReactStatics(withUser(Private), WrappedComponent)
  }
  
  export default PrivateRoute