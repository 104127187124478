import React, {Component} from 'react';
import RandomColor from 'randomcolor';
import './total-cards.css';

class TotalCard extends Component {

    constructor(props){
        super(props);
        this.state =  {
            aggregateby:[],
            totals:[],
            data:[],
            colors:RandomColor({count:10, hue:'blue', luminosity:'dark'})
        }
        this.calculateTotals = this.calculateTotals.bind(this);
    }



    static getDerivedStateFromProps(props, state){  
        if(props.data !==  state.data){
            return {
                data:props.data,
                aggregateby:props.aggregateby
            }
        }
        return state;
    }
   
    componentDidUpdate(){
        console.log('updating component');
    }


    calculateTotals(){
        // console.log('calculating totals');
        let totals = [];
        let aggregateby = this.props.aggregateby;
        if(this.props.data.length > 0){
            for(let i in aggregateby){
                let new_total = {totalby:aggregateby[i].title};
                let aggregate = aggregateby[i];

                new_total._totals = {};
                new_total.total_index =[... new Set(this.props.data.reduce((a, b)=>{
                    return  a = a.concat(b[aggregateby[i].accessor]);    
                }, []))];

                new_total._totals = Object.assign({}, ...new_total.total_index.map(item =>({[item]:{
                    fields: Object.assign({},  ...aggregate.fields.map(field=>({[field["title"]]: {...field, value:0}})))
                }})));
                let temp_lift = {};
                this.state.data.map((d)=>{
                    let total = new_total._totals[d[aggregate.accessor]];

                    let fields = total.fields;

                    for(let k in fields){
                        if(d[fields[k].accessor] > 0 && fields[k].aggregate === "Sum"){
                            fields[k].value += d[fields[k].accessor];
                        }
                        if(fields[k].aggregate == "Divide"){
                            let nominator = fields[k].accessor[0];
                            let denominator = fields[k].accessor[1];
                            fields[k].value = fields[nominator].value/fields[denominator].value;
                        }
                        if(fields[k].aggregate == "Lift"){
                            let liftName = `${d[aggregate.accessor]}-${fields[k].title}`;
                            let nominator = fields[k].accessor[0];
                            let denominator = fields[k].accessor[1];

                            if(!temp_lift[liftName]){
                                temp_lift[liftName] = {};
                                temp_lift[liftName].denominator = 0;
                                temp_lift[liftName].nominator = 0;
                                temp_lift[liftName].value = 0;
                            }
                            //strict include of numbers that have results only
                            temp_lift[liftName].nominator += d[nominator] > 0 && d[denominator] > 0 ? d[nominator] : 0;
                            temp_lift[liftName].denominator += d[denominator] > 0 && d[nominator] > 0 ? d[denominator] : 0;
                            
                            temp_lift[liftName].value = (temp_lift[liftName].nominator - temp_lift[liftName].denominator) /  temp_lift[liftName].denominator;
                            fields[k].value = temp_lift[liftName].value;
                        }

                        if(fields[k].type === "Currency"){
                            fields[k].formatted_value = fields[k].value?fields[k].value.toLocaleString('en-us', {style:"currency", currency:"USD"}) : "$0";
                        }
                        if(fields[k].type === "Percentage"){
                            fields[k].formatted_value = `${Math.round((temp_lift[`${d[aggregate.accessor]}-${fields[k].title}`].value) * 100)||0} %`;
                            // console.log(fields[k]);
                        }
                    }
                });

                // console.log(temp_lift);
                totals.push(new_total);
            }
        }
        return totals;
    }



    render(){

        // if(this.state.data.length > 0){
                // return(
                return(
                    <div className="total">
                        {this.calculateTotals().map((d)=>{
                            console.log(d);
                            return (
                            <div key={d.totalby}>
                                <div className="total-wrapper"> 
                                <h2 className="total-by-header">{d.totalby}</h2>
                                    {
                                    Object.keys(d._totals).map((key,i)=>{
                                        return(
                                        <div key={key} className="total-card">
                                            <div className="total-card-heading" style={{backgroundColor:this.state.colors[i]}}>
                                                <h2>{key}</h2>
                                            </div>
                                                
                                            <div className="total-card-body">
                                                {
                                                Object.keys(d._totals[key].fields).map(item=><span key={item}>{item}: {d._totals[key].fields[item].formatted_value?d._totals[key].fields[item].formatted_value:d._totals[key].fields[item].value}</span>)
                                                }
                                            </div>

                                        </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                            )

                        })}
                    </div>
                )
                // )
        // }else{
        //     return null;
        // }
    }

    
}


export default TotalCard;