import React, { Component } from 'react';
import gql from "graphql-tag";
import {withUser} from '../context/userContext';

class Home extends Component {
  query(){
       return  gql`
        } 
    }`;
  }

  render() {
    return (
       <div className="container-fluid">
        
       </div> 
    );
  }
}

export default withUser(Home);
