import React, {Component} from 'react';
import { Statistic, Card, Row, Col, Icon, Progress } from 'antd';


class CallCenterStat extends Component {

    constructor(props){
        super(props);
        console.log(props);
        this.getDefault = this.getDefault.bind(this);
    }

    getDefault(data, name){
        let def;
        for(let i in data){
            if(data[i].NAME === name ){
                def = data[i];
            }else if(data[i].NAME === "Default"){
                def = data[i];
            }else if(data[i].NAME === "TG-Default"){
                def = data[i];
            }
            else if(data[i].NAME === "FTE TIBMEDICARE FTE"){
              def = data[i];
            }
            if(data[i].NAME.indexOf('Default')>-1){
                def = data[i];
            }
        }
        if(def){
            return (
                    <div className="call-center-stat-inside">
                         <div style={{ background: '#ECECEC', }}>
                            <Row>
                            <Col span={10}>
                                <Card style={{background:"#ECECEC"}} bodyStyle={{padding:0}}>
                                    <Progress type="dashboard" percent={def.callsInQueue} format={percent => `${percent} in Queue`} />

                                </Card>
                            </Col>
                            <Col span={14}>
                                <Card style={{background:"#f5f5f5"}}>
                                <Statistic
                                    title="Active Agents"
                                    value={def.agentsActive}
                                    precision={0}
                                    valueStyle={{ color: 'black', 'fontSize':'2.65em' }}
                                    prefix={<Icon type="team" />}
                                />
                                </Card>

                            </Col>


                            <Col span={12}>
                                <Card>

                                <Statistic
                                    title="Ready"
                                    value={def.agentsInQueue}
                                    precision={0}
                                    valueStyle={{ color: 'red', 'fontSize':'2.65em' }}
                                    prefix={<Icon type="user" />}
                                />
                                <Progress
                                        strokeColor={{
                                            '0%': 'red',
                                            '100%': 'red',
                                        }}
                                        percent={Math.ceil((def.agentsInQueue/def.agentsActive)*100)}
                                />
                                </Card>
                            </Col>

                            <Col span={12}>
                                <Card>
                                <Statistic
                                    title="Not Ready"
                                    value={def.agentsNotReadyForCalls}
                                    precision={0}
                                    valueStyle={{ color: 'blue','fontSize':'2.65em' }}
                                    prefix={<Icon type="close-square" />}
                                />
                                 <Progress
                                        strokeColor={{
                                            '0%': '#87d068',
                                            '100%': 'blue',
                                        }}
                                        percent={Math.ceil((def.agentsNotReadyForCalls/def.agentsActive)*100)}
                                />
                                </Card>

                            </Col>
                            <Col span={24}>
                                <Card>
                                <Statistic
                                    title="On Call"
                                    value={def.agentsOnCall}
                                    precision={0}
                                    valueStyle={{ color: 'green','fontSize':'2.65em' }}
                                    prefix={<Icon type="phone" />}
                                />
                                <Progress
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={Math.floor((def.agentsOnCall/(def.agentsActive-def.agentsNotReadyForCalls))*100)}
                                />
                                </Card>

                            </Col>

                            </Row>
                        </div>






                    {/* <h2>Queue : <span className="stat">{def.callsInQueue}</span></h2>  */}
                    </div>)
        }else{
            return(
                <div>
                    { data.map((d)=>{
                    return (
                        <h5>{d.NAME}-ready-{d.agentsInQueue}</h5>
                    )})
                }
                </div>
            )

        }

    }

    determine_center(){
        console.log(this.props.data.skills);

            return (
                <div className="call-center-stat">
                    <Card title="Card title" bordered={true}
                    headStyle={{"color":"white", "fontSize":"2.2em", "borderBottom":"1px solid black","backgroundImage": "linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%)"}}
                    title={this.props.data.call_center}
                    style={{ background: '#ECECEC' }}
                    bodyStyle={{padding:"0"}}
                    >
                        <div className="stat-body">{this.getDefault(this.props.data.skills, "Default skill")}</div>
                    </Card>
                </div>
            )
    }

    render(){
    return(
        this.determine_center()
    )

    }


}


export default CallCenterStat;
