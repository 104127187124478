import React, {Component} from 'react';


class ForecastCard extends Component {

    percentageLiftColumn(value){
        let percent = value;
        return(
            <div className="percentagelift">
            <div
              className="percentagelift-left"
              style={{
                height: '100%',
                // width: `${percent<0 ? percent * -100 : 100}%`,
                // marginLeft:`${percent < - 0.01 ? 100 - (percent * -100) : 0}%`,
                background: percent < 0 ? `radial-gradient(circle farthest-corner at 11.6% 23%,  rgba(213,31,6,1) 0%, rgba(241,132,60,1) 90% )` : "",
                borderRadius: '2px',
                transition: 'all .2s ease-out'
              }}
            />
            <div
              className="percentagelift-right"
              style={{
                //   width: `${percent >= 1 ? 100 : percent  * 100}%`,
                  height: '100%',
                  background: percent > 0 ? 'radial-gradient( circle 916px at -1.1% 50.2%,  rgba(16,233,139,1) 13%, rgba(0,176,228,1) 100.2% )' : "",
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              />
              <span className="percentagelift-value"
                     style={{
                      height: '100%',
                    //   left:`${percent < 0.01 ? 50 : -50}%`,
                      transition: 'all .2s ease-out'
                    }}
              >
              {`${Math.round(percent * 100)}%`}
              </span>

          </div>
        )
    }

    forecastGrid(data, calls_tracked){
        return(
            <div className="forecast-grid">
                <div className="forecast-row forecast-row-header">
                    <span className="header">Block</span>
                    <span className="header">Var</span>
                    <span className="header">Forecast</span>
                    <span className="header">Actual</span>
                    {/* <span className="header">Paid</span> */}
                    <span className="header">+/-</span>
                    <span className="header">RPC</span>
                    <span className="header">Raw</span>
                    <span className="header">Paid</span>
                    <span className="header">Conv.</span>


                </div>
                <ul className="forecast-grid-body">
                    {data.map((d,i)=>{
                        return (
                            <li className="forecast-row" key={d._id}>
                                
                                <span>
                                   {d.end_time}
                                </span>
                                <span>
                                    {this.percentageLiftColumn(d.variance_percentage)}
                                </span>
                                <span>
                                    {d.forecasted}
                                </span>
                                <span>
                                    {d[`total_${calls_tracked.toLowerCase()}`]}
                                </span>
                                <span>
                                    {d.variance}
                                </span>
                                <span>
                                    {d.running_rpc ? d.running_rpc.toLocaleString('en-US', {style:'currency', currency:"USD"}): ""}
                                </span>
                             
                                <span>
                                    {d.raw}
                                </span>
                                <span>
                                    {d.paid}
                                </span>
                                <span>
                                    {d.conversion ? d.conversion.toLocaleString('en-US', {style:'percent'}): ""}
                                </span>
                            
                            </li>  
                        ); 
                    })}
                </ul>

            </div>

        )
    }



    render(){
    return(

        <div className="card forecast-card">
            <div className="card-header">
                <div className="card-header-meta">
                    <h2>{this.props.data.name}</h2>
                </div>
                <ul className="forecast-stats">
                    <li>
                        <span>Raw: {this.props.data.total_raw}</span> 
                    </li>
                    <li>
                        <span>Paid: {this.props.data.total_paid}</span>
                    </li>
                    <li>
                        <span>Revenue: {this.props.data.total_revenue.toLocaleString('en-us', {style:"currency", currency:"USD"})}</span>
                    </li>
                    <li>
                        <span>Conversion: {this.props.data.total_conversion.toLocaleString('en-us',{style:"percent"})}</span>
                    </li>
                </ul>
            </div>
            <div className="card-body">
                {this.forecastGrid(this.props.data.line_items,this.props.data.calls_tracked)}
            </div>
        </div>
    )
   
    }

    
}


export default ForecastCard;