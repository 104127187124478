import React, {Component} from 'react';
import {Query} from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';

const PROJECTIONS_QUERY = gql `
    query projections($start:Date!, $end:Date!){
        prelogProjections(start:$start, end:$end){
            time_stamp
            projected_calls
        }
    }`;

class Projections extends Component {
    constructor(props){
        super(props);
        this.state = {
            start:moment().startOf('day').toDate().toISOString(),
            end:moment().endOf('day').toDate().toISOString(),
        }
        console.log(this.state);

    }
    render(){
        return(
            <div>
                <Query query={PROJECTIONS_QUERY} variables={this.state}>
                    {({loading, error, data})=>{
                        if(loading) return `Loading...`
                        if(error) return `${error} - Error`
                        console.log(data);
                        return(
                            <LineChart width={600} height={300} data={data.prelogProjections}>
                                    <Line type="monotone" dataKey="projected_calls" stroke="#8884d8" />
                                    <CartesianGrid stroke="#ccc" />
                                    <XAxis dataKey="time_stamp" 
                                        tickFormatter={(tick)=>{
                                            // console.log(props)
                                            return moment(tick).format("h A");
                                            // return<span>{props.payload.value}</span>
                                        }}/>
                                    <YAxis />
                                    <Tooltip labelFormatter={(value)=>{
                                        return moment(value).format("h A");
                                    }}/>
                            </LineChart>
                        )
                    }}
                </Query>


            </div>
            
        )
       return <div>Projections</div>
    }
}

export default Projections;