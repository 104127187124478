import React, {Component} from 'react';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import {Loader} from '../loader/loader';
import Select from 'react-select';
import moment from 'moment';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import XLSX from 'xlsx';


const ReactTableFixedColumns = withFixedColumns(ReactTable);

class ReportTable extends Component {
    constructor(props){
        super(props);
        this.state = {
            columns:props.columns,
            data:props.data

        }
        this.exportReport = this.exportReport.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState){
        return {
            columns:nextProps.columns,
            data:nextProps.data
        }
    }
    exportReport(e){
        e.preventDefault();
        let data = this.selectTable.getResolvedState();
        let columns = data.columns;
        console.log(data);
        // console.log(columns);
        // console.log(data);
        let data_to_export = data.sortedData.map((d)=>{
            let object_to_build = {};
            if(data.hasHeaderGroups){
                for(let k in data.columns){
                        let columns = data.columns[k].columns;
                        console.log(columns);
                        for(let i in columns){
                            object_to_build[columns[i].Header] = d[columns[i].accessor];
                            if(columns[i].Header === "Time"){
                                object_to_build[columns[i].Header ]= moment(d[columns[i].accessor]).format("hh:mm a");
                            }
                            if(columns[i].Header === "Spend"){
                                object_to_build[columns[i].Header ]= d[columns[i].accessor]? d[columns[i].accessor].toLocaleString('en-US', {currency:"USD", style:"currency"}) : "$0.00";
                            }
                        }
                }
               
            }else{
                for(let i in columns){
                    object_to_build[columns[i].Header] = d[columns[i].accessor];
                    if(columns[i].Header === "Time"){
                        object_to_build[columns[i].Header ]= moment(d[columns[i].accessor]).format("hh:mm a");
                    }
                    if(columns[i].Header === "Spend"){
                        object_to_build[columns[i].Header ]= d[columns[i].accessor]? d[columns[i].accessor].toLocaleString('en-US', {currency:"USD", style:"currency"}) : "$0.00";
                    }
                }
            }
           
            return object_to_build;
        });
        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(data_to_export);
        XLSX.utils.book_append_sheet(workbook, worksheet, `Comps Report`);
        XLSX.writeFile(workbook, `Comps Report - ${moment().format('MM.DD.YYYY - hh.mm.ss')}.xlsx`)
    }

    percentageLiftColumn(row){
        return(
            <div className="percentagelift">
            <div
              className="percentagelift-left"
              style={{
                height: '100%',
                width: `${row.value<0 ? row.value * -100 : 100}%`,
                marginLeft:`${row.value < - 0.01 ? 100 - (row.value * -100) : 0}%`,
                backgroundColor: row.value < 0 ? "red" : "",
                borderRadius: '2px',
                transition: 'all .2s ease-out'
              }}
            />
            <div
              className="percentagelift-right"
              style={{
                  width: `${row.value * 100}%`,
                  height: '100%',
                  backgroundColor: row.value > 0 ? '#85cc00' : "",
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              />
              <span className="percentagelift-value"
                     style={{
                      height: '100%',
                      left:`${row.value < 0.01 ? 65 : 10}%`,
                      transition: 'all .2s ease-out'
                    }}
              >{`${Math.round(row.value * 100)}%`}</span>

          </div>
        )
    }
    table_sub({row, original}){
        // console.log(row);
        // let original = row;
        if(original.comp_airings){
            return  (
            <div style={{maxWidth:"50%", padding:"0 1rem 0 1rem", borderRadius:"40px", margin:"1rem", border:"1px solid whitesmoke", boxShadow:"0 6px 15px rgba(36, 37, 38, 0.08)"}}>
            <h2 style={{textAlign:"center"}}>{`${original.station} - ${original.match_type} - Comparables`}</h2>
            <table className="table" >
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Creative</th>
                        <th>Station</th>
                        <th>Program</th>
                        <th>Spend</th>
                        <th>Raw</th>
                        <th>Raw 15</th> 
                    </tr> 
                </thead>
                <tbody>
                    {original.comp_airings.map(d=>{
                        return(
                        <tr key={d.time_stamp} style={{padding:"1rem"}}>
                            <td>{d.date_aired}</td> 
                            <td>{moment(d.time_stamp).format("hh:mm a")}</td>
                            <td>{d.creative}</td>
                            <td>{d.station}</td>  
                            <td>{d.program_aired}</td>
                            <td>{d.spend.toLocaleString('en-US', {style:"currency", currency:"USD"})}</td>
                            <td>{d.raw}</td>
                            <td>{d.raw_15}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
            )
        }
        return <div></div>
    }
   
    
    render(){
        if(this.state.data){
            return (
                <div className="card report-card">

                <div className="card-header">
                    <div className="actions">
                        {this.state.data? <button className="btn" onClick={this.exportReport}><i className="fa fa-arrow-down fa-2x"></i></button>:null}
                    </div>
                </div>

                <div className="card-body">
                    <div className="row">
                        <ReactTableFixedColumns data={this.state.data}  
                            columns={this.state.columns}  
                            className="-striped -highlight table" 
                            filterable 
                            SubComponent={this.table_sub}
                            onFilteredChange= {this.dataChanged}
                            innerRef={(r) => {
                                this.selectTable = r;
                              }}
                           
                            />
                    </div>
                </div>
                </div>
            )
        }
    }
}

export default ReportTable;