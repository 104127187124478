import React, {Component} from 'react';
import gql from 'graphql-tag';
import {ApolloConsumer} from 'react-apollo'
import {withUser} from '../context/userContext';
import './login.css';

const LOGIN_QUERY = gql`
query login($username:String!, $password:String!){
    login(username:$username,password:$password){
      token
      user{
        username
      }
    }
  }
`
class Login extends Component{
    constructor(props){
        console.log(props);
        super(props);
        this.state={
            username:"",
            password:""
        }
        this.handleChange = this.handleChange.bind(this);
        
    }

    handleChange(e){
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;

        this.setState({[name]:value});
    }

    render(){
        return(
            <ApolloConsumer>
                    {client => (
                        <div className="login-wrapper">
                        <div className="login">
                            <div className="card-body"> 

                            <form onSubmit={async(e) =>{
                                e.preventDefault();
                                try{
                                    let success = await client.query({query:LOGIN_QUERY,variables:this.state});
                                    this.props.login(true, success.data.login);
                                    this.props.history.push(`${this.props.location.search?this.props.location.search.replace('?return=',''):'/'}`)
                                }catch(e){
                                    console.log(e);
                                }
                                
                                
                            }}> 
                                <img className="brand-logo" src="/logo.png"/>
                                <div className="form-group">
                                    <label htmlFor="">User Name</label>
                                    <input className="form-control" type="text" placeholder="User Name" name="username" value={this.state.username} onChange={this.handleChange}  required/>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Password</label>
                                    <input className="form-control" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange} required/>
                                </div>
                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-success">Login</button>
                                </div>
                            </form>
                            </div>

                        </div>
                        </div>
                    )}
            </ApolloConsumer> 
        )
    }
}
export default withUser(Login);