import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './home/home'
import CampaignList from  './campaign/campaign_list.jsx';
import Campaign from './campaign/campaign';
import Prelogs from './prelogs/prelogs';
import Reports from './reports/reports.jsx';
import CompareLogs from './reports/compare-logs';
import CompareReportsOverview from './reports/compare-logs-overview';
import Spend from './reports/spend.jsx';
import AddLog from './tools/addlogs';
import Login from './login/login';
import Projections from './projections/projections';
import Forecast from './forecast/forecast';
import CallCenters from './callCenters/callCenters';
import Overview from './reports/overview.jsx';



class App extends Component {
  render() {
    return (

          <Switch >
            <Route exact path='/login' component={Login}/>
            <Route exact path='/' component={Home}/>
            <Route exact path='/projections' component={Projections}/>
            <Route exact path='/campaigns' component={CampaignList} />
            <Route exact path='/campaigns/:campaign_id' component={Campaign}/>
            <Route exact path='/prelogs' component={Prelogs}/>
            <Route exact path='/reports' component={Reports}/>
            <Route exact path="/reports/compare-logs" component={CompareLogs}/>
            <Route exact path="/reports/compare-logs-overview" component={CompareReportsOverview}/>
            <Route exact path="/reports/spend" component={Spend}/>
            <Route exact path="/reports/overview" component={Overview}/>
            <Route exact path="/tools/add-logs" component={AddLog}/>
            <Route exact path="/forecasts" component={Forecast}/>
            <Route exact path="/call-centers" component={CallCenters}/>
          </Switch>

    );
  }
}

export default App;
