import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/lib/css/_datepicker.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import ApolloProviderCustom from './apolloClient.jsx';
import {UserProvider} from './context/userContext.jsx';


ReactDOM.render(
                <BrowserRouter>
                    <UserProvider>
                            <ApolloProviderCustom>
                                    <App />
                            </ApolloProviderCustom>
                    </UserProvider>
                </BrowserRouter>
                , document.getElementById('root'));
registerServiceWorker();
