import React,  {Component} from 'react';
import {Mutation} from 'react-apollo';
import gql from 'graphql-tag';
export const UPLOAD_FILE =  gql `
    mutation addPrelogs($file:Upload!){
        addPrelogs(file: $file){
            filename
            mimetype
            encoding
        }
    }
`
class AddLog extends Component{
        
    constructor(props){
        super(props);
        this.state={file:"", file_name:""}
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e){
        e.preventDefault();
        this.setState({[e.target.name]:e.target.files}, ()=>{

        })
    }

    render(){
        return(
            <div>
                <input type="file" onChange={this.handleChange} name="file" value={this.state.filename}/>
                <Mutation  mutation={UPLOAD_FILE}>
                    {(uploadFile, {loading, error})=>{
                         if (loading) return <p>Loading...</p>;
                         if (error) return <p>Error :(</p>;
                        return(
                            <button onClick={
                                async ()=>{
                                    await uploadFile({variables:this.state})
                                    console.log('done');
                                    this.setState({file:[],file_name:""});
                                }
                            }>Upload</button>
                        )}
                    }

                </Mutation>
            </div>
        )
    }
}

export default AddLog;