import React, { Component } from 'react';
import gql from "graphql-tag";
import { Query } from "react-apollo";
import {Link} from 'react-router-dom';
import './campaign.css';
import Buyers from './buyers.jsx';
import BuyerForecasts  from './BuyerForecasts.jsx';
import moment from 'moment';

class Campaign extends Component {
    constructor(props){
        super(props);
        this.state = {
            _id:this.props.match.params.campaign_id
        }
    }
    query(){
       return  gql`
        query campaignby_id($_id:String!){
            campaignby_id(_id:$_id){
                _id
                name
                buyers{
                    buyer
                    campaigns
                }
                publishers{
                    publisher
                    campaigns
                }
                creatives
                buyerSchedules{
                    _id
                    name
                    buyer
                    campaigns{
                        buyer_campaign_id
                        buyer_campaign
                    }
                    buyer_id
                    calls_tracked
                    active
                    total_calls
                    start_time
                    end_time
                    line_items{
                        start_time
                        end_time
                        start_date
                        end_date
                        forecasted
                    }
                    email 
                    custom
                }
            }
           
            
        }
       `    
    }
    getBuyers(buyers){
        return(
            <table className="table">
            <thead>
                <tr>
                    <td>Buyer</td>
                    <td>Campaigns</td>
                </tr>
            </thead>
            <tbody>
                {
                    buyers.map((buyer)=>{
                        return(
                            <tr key={buyer.buyer}>
                                <td>{buyer.buyer}</td>
                                <td>{this.getBuyerCampaigns(buyer.campaigns)}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
            </table>
        )
    }
    getPublishers(publishers){
        return(
            <table className="table">
            <thead>
                <tr>
                <th>Publishers</th>
                <th>Campaigns</th>
                </tr>
            </thead>
            <tbody>
                {publishers.map((publisher)=>{
                    return(
                            <tr key={publisher.publisher}>
                            <td>{publisher.publisher}</td>
                            <td>{this.getBuyerCampaigns(publisher.campaigns)}</td>
                            </tr>
                    );
                })}
            </tbody>
            </table>
        
        )
    }
  getBreadCrumbs(field, data){
    return (
    <nav aria-label="breadcrumb"  >
        <ol className="breadcrumb">
            <li className="breadcrumb-item" aria-current="page">
            <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item" aria-current="page">
            <Link to="/campaigns">Campaigns</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{data[field]}</li>
        </ol>
    </nav>);
  }
  
  getBuyerCampaigns(buyerCampaings){
    return (
        buyerCampaings.map((d,i)=> <span  key={i} className="badge badge-primary">{d}</span> )
    )
  }

 



  render() {
    return (

          <Query query={this.query()} variables={this.state}>
            {({loading, error, data}) =>{
                if(loading) return null;
                if(error) return `${error}`;
                return(
                    <div className="campaign">
                        {this.getBreadCrumbs('name',data.campaignby_id)}d
                        <div className="row justify-content-center campaigns">
                            <div className="col-11">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Creatives</h3> 
                                    </div>
                                    <div className="card-body">
                                        <div className="buyers">
                                            {data.campaignby_id.creatives.map((creative)=>{
                                            return <span key={creative} className="badge badge-primary creative-span">{creative}</span>
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-11">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Buyer Schedules</h3> 
                                    </div>
                                    <div className="card-body">
                                        <BuyerForecasts forecasts={data.campaignby_id.buyerSchedules} campaign_id={data.campaignby_id._id}/>
                                    </div>
                                </div>
                            </div>
                        
                            <Buyers buyers={data.campaignby_id.buyer}/>
                            <div className="col-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Publishers</h3> 
                                    </div>
                                    <div className="card-body">
                                        <div className="buyers">
                                            {this.getPublishers(data.campaignby_id.publishers)}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        
                            
                        
                        </div>
                    </div>
                )
            }}
          </Query>

    );
  }
}

export default Campaign;
