import React, {Component} from 'react';
import {ApolloConsumer, Query} from 'react-apollo';
import gql from 'graphql-tag';
import './compare-logs.css';
import ReactTable from "react-table";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import XLSX from 'xlsx';
import TotalCards from '../total-cards/total-cards.jsx';
import {Loader} from '../loader/loader';
import Select from 'react-select';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import privateRoute from '../private-route/private-route';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const COMPARE_LOGS_REPORT = gql `
    query prelogCompare($start:Date!, $end:Date!, $creatives:[String]!, $compare_to_creative:[String]!, $min_spend:Float, $start_comparison:Date, $end_comparison:Date){
        prelogCompare(start:$start, end:$end, creatives:$creatives, compare_to_creative:$compare_to_creative, min_spend:$min_spend, start_comparison:$start_comparison, end_comparison:$end_comparison){
            date_aired
            phone
            station
            time_stamp
            raw
            raw_15
            comparable_raw_15
            comparable_paid_15
            comparable_raw
            percent_lift_raw_15
            percent_lift_paid_15
            percent_lift_raw
            match_type
            program_aired
            creative
            spend
            paid_15
            max_raw_15
            max_paid_15
            max_raw
            max_creative
            comp_airings{
                date_aired
                station
                time_stamp
                creative
                spend
                program_aired
                raw
                raw_15
            }
        }
    }
`
const CREATIVE_QUERY = gql  `
    query creatives{
        creatives{
            name
        }
    }
`

class CompareReportsOverview extends Component {
    constructor(props){
        super(props);
        this.state = {
            start:moment().startOf('day'),
            end:moment().endOf('day'),
            start_comparison:moment(new Date('10/03/2018')).startOf('day'),
            end_comparison:moment(new Date('10/14/2018')).endOf('day'),
            creatives:["Joe Script 3", "Joe Testimonial AEP"],
            compare_to_creative:["Joe Script 3", "Joe Testimonial AEP"],
            selected_creatives:[],
            selected_compare_to_creatives:[],
            data_for_totals:[],
            total:{}
        }
        this.fetchReport = this.fetchReport.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showQuery = this.showQuery.bind(this);
        this.dataChanged = this.dataChanged.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.exportReport = this.exportReport.bind(this);
        this.handleCreativeChange = this.handleCreativeChange.bind(this)
    }
    handleDateChange(dateName, value){
        if(dateName.indexOf('end') > -1){
            this.setState({[dateName]:moment(value).endOf('day')})
        }else{
            this.setState({[dateName]:value});
        }
        // this.setState({[dateName]:value});
    }
    handleCreativeChange(e, name, d){
        let creatives = e.map(d => d.value);
        let state_name = name == "selected_creatives"?"creatives":"compare_to_creative";
        this.setState({[name]:e, [state_name]:creatives});
    }
    handleChange(e){
        e.preventDefault();
        let target = e.target;
        let name = target.name;
        let value = target.value;
        if(name === "creatives" ){
            console.log(target.options);
            value =  [...target.options].filter(o=>o.selected).map(o=>o.value);
            console.log(value);
            // value = target.value.split(',').map(d=>d.trim());

        }
        if(name === "compare_to_creative"){
            console.log(target.options);
            value =  [...target.options].filter(o=>o.selected).map(o=>o.value);
            console.log(value);
        }
        this.setState({[name]:value}, ()=>{
            console.log(this.state);
        });
    }
    onDataRecieved(data) {
        console.log(data);
        this.setState({data:data.prelogCompare, data_for_totals:data.prelogCompare},()=>{
            // this.calculateTotals(this.state.data);
        })
    }
    async fetchReport(client){
        let variables = {...this.state};
        variables.creatives = this.state.selected_creatives.map(d=>d.value);
        delete variables["selected_creatives"];
        console.log(variables);
        const {data} = await client.query({
            query:COMPARE_LOGS_REPORT,
            variables:variables,
            fetchPolicy:"network-only"
        });
        this.onDataRecieved(data);
    }

    exportReport(e){
        e.preventDefault();
        let data = this.selectTable.getResolvedState();
        let columns = data.columns;
        
        // console.log(columns);
        // console.log(data);
        let data_to_export = data.sortedData.map((d)=>{
            let object_to_build = {};
            if(data.hasHeaderGroups){
                for(let k in data.headerGroups){
                    let columns = data.columns[k].columns;
                    console.log(columns);
                    for(let i in columns){
                        object_to_build[columns[i].Header] = d[columns[i].accessor];
                        if(columns[i].Header === "Time"){
                            object_to_build[columns[i].Header ]= moment(d[columns[i].accessor]).format("hh:mm a");
                        }
                    }
                }
               
            }else{
                for(let i in columns){
                    object_to_build[columns[i].Header] = d[columns[i].accessor];
                    if(columns[i].Header === "Time"){
                        object_to_build[columns[i].Header ]= moment(d[columns[i].accessor]).format("hh:mm a");
                    }
                }
            }
           
            return object_to_build;
        });
        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(data_to_export);
        XLSX.utils.book_append_sheet(workbook, worksheet, `Comps Report`);
        XLSX.writeFile(workbook, `Comps Report - ${moment().format('MM.DD.YYYY - hh.mm.ss')}.xlsx`)
    }

   
    dataChanged(filter, column){
        let data = this.selectTable.getResolvedState();
        this.setState({data_for_totals:data.sortedData});
    }

    percentageLiftColumn(row){
        return(
            <div className="percentagelift">
            <div
              className="percentagelift-left"
              style={{
                height: '100%',
                width: `${row.value<0 ? row.value * -100 : 100}%`,
                marginLeft:`${row.value < - 0.01 ? 100 - (row.value * -100) : 0}%`,
                backgroundColor: row.value < 0 ? "red" : "",
                borderRadius: '2px',
                transition: 'all .2s ease-out'
              }}
            />
            <div
              className="percentagelift-right"
              style={{
                  width: `${row.value * 100}%`,
                  height: '100%',
                  backgroundColor: row.value > 0 ? '#85cc00' : "",
                  borderRadius: '2px',
                  transition: 'all .2s ease-out'
                }}
              />
              <span className="percentagelift-value"
                     style={{
                      height: '100%',
                      left:`${row.value < 0.01 ? 65 : 10}%`,
                      transition: 'all .2s ease-out'
                    }}
              >{`${Math.round(row.value * 100)}%`}</span>

          </div>
        )
    }

    componentWillMount(){
        const columns = [
            {Header:"Log Info", 
            fixed:"left",
                columns:[
                    {
                        Header: 'Date',
                        accessor: 'date_aired',
                      },{
                          Header: 'Time',
                          accessor: 'time_stamp',
                          Cell: props => moment(props.value).format('hh:mm a')
                      },{
                          Header:'Creative',
                          accessor: 'creative',
                          filterMethod:this.defaultFilter
                          
                      },{
                          Header:'Station',
                          accessor: 'station',
                      },
                      {
                          Header:"Phone",
                          accessor:"phone"
                      },
                      {
                          Header:'Program',
                          accessor:'program_aired'
                      },
                      {
                          Header:'Spend',
                          accessor:'spend',
                          Cell:props=>props.value.toLocaleString('en-us', {style:"currency", currency:"USD"})
                      },
                ]

            },
            {
                Header:"Actuals",
                columns:[ {
                    Header:'Raw',
                    accessor:'raw',
                    Cell:props=>props.value || 0
                },
                {
                    Header:"Raw 15",
                    accessor:"raw_15",
                    Cell:props=>props.value || 0
                },
                ]

            },
            {
                Header:"Comparables",
                columns:[{
                        Header:"Match Type",
                        accessor:"match_type",
                        filterMethod: (filter, row) => {
                            let filter_by = [...filter.value].filter(o=>o.selected).map(o=>o.value);
                            if(filter_by.indexOf('All') > -1 || filter_by.length === 0){
                              return true;
                            }
                            if(filter_by.indexOf(row[filter.id]) >= 0){
                                return true;
                            }
                        },
                        Filter: ({ filter, onChange }) =>{
                          return(
                          <select
                            onChange={event => onChange(event.target.options)}
                            style={{ width: "100%" }}
                            multiple
                          >
                            <option value="All">All</option>
                            <option value="Exact">Exact</option>
                            <option value="Network">Network</option>
                            <option value="No Match">No Match</option>
                          </select>
                          )
                      }
                    },
                    {
                        Header:"Comp Raw 15",
                        accessor:"comparable_raw_15",
                        minWidth:150,
                        Cell:props=>props.value || 0
                    }
                ]
            },
            { Header:"Lift",
              columns:[
                    {
                        Header:"Lift 15 Raw",
                        accessor:"percent_lift_raw_15",
                        Cell: this.percentageLiftColumn,
                        minWidth:150,
                        sortMethod:(a, b)=>{
                              if(a > b){
                                  return 1
                              }
                              if(a < b & a != 0){
                                  return -1
                              }
                              return -1
                        }
                    }]
            }
        ];

        this.setState({columns:columns});
    }
    table_sub({row, original}){
        // console.log(row);
        // let original = row;
        if(original.comp_airings){
            return  (
            <div style={{maxWidth:"50%", padding:"0 1rem 0 1rem", borderRadius:"40px", margin:"1rem", border:"1px solid whitesmoke", boxShadow:"0 6px 15px rgba(36, 37, 38, 0.08)"}}>
            <h2 style={{textAlign:"center"}}>{`${original.station} - ${original.match_type} - Comparables`}</h2>
            <table className="table" >
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Creative</th>
                        <th>Station</th>
                        <th>Program</th>
                        <th>Spend</th>
                        <th>Raw</th>
                        <th>Raw 15</th> 
                    </tr> 
                </thead>
                <tbody>
                    {original.comp_airings.map(d=>{
                        return(
                        <tr key={d.time_stamp} style={{padding:"1rem"}}>
                            <td>{d.date_aired}</td> 
                            <td>{moment(d.time_stamp).format("hh:mm a")}</td>
                            <td>{d.creative}</td>
                            <td>{d.station}</td>  
                            <td>{d.program_aired}</td>
                            <td>{d.spend.toLocaleString('en-US', {style:"currency", currency:"USD"})}</td>
                            <td>{d.raw}</td>
                            <td>{d.raw_15}</td>
                        </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>
            )
        }
        return <div></div>
    }
    showData(){
        if(this.state.data){
            return (
                <ReactTableFixedColumns data={this.state.data}  
                            columns={this.state.columns}  
                            className="-striped -highlight table" 
                            filterable 
                            onFilteredChange= {this.dataChanged}
                            innerRef={(r) => {
                                this.selectTable = r;
                              }}
                            SubComponent={this.table_sub}
                            />
            )
        }
    }
    
    showQuery(){
        return(
        <ApolloConsumer>
            {client => (
                    <button className="btn btn-outline-success" onClick={()=>this.fetchReport(client)}>Run Report</button>
            )}
        </ApolloConsumer>
        )
    }
    
    render(){
        return (
            <div>
                {/* <Loader loading={true}></Loader> */}
                <div className="report-parameters">
                        <h2 className="header">Compare Logs</h2>
                        <div className="fields vertical">
                                <div className="form-group">
                                        <label htmlFor="start">Start Date</label>
                                        <DatePicker selected={this.state.start}
                                                onSelect={(date)=>{this.handleDateChange("start", date)}}
                                                className="form-control"
                                        />
                                </div>
                                <div className="form-group">
                                        <label htmlFor="end">End Date</label>
                                        <DatePicker selected={this.state.end}
                                                onSelect={(date)=>{this.handleDateChange("end", date)}}
                                                className="form-control"
                                        />
                                </div>
                        </div>
                        <div className="fields vertical">
                            <div className="form-group">
                                    <label htmlFor="creatives">Creatives</label>
                                    <Query query={CREATIVE_QUERY} >
                                        {({loading, error, data})=>{
                                            if(loading) return "Loadding ...";
                                            if(error) return `error! ${error.message}`;
                                            let options = data.creatives.map(d=>{return {value:d.name, label:d.name}})
                                            return(
                                                <Select value={this.state.selected_creatives}
                                                        onChange={(e)=>{this.handleCreativeChange(e, "selected_creatives")}}
                                                        options={options}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        placeholder={"Creatives"}
                                                        classNamePrefix="custom-select"
                                                        className="custom-select"
                                                        >
                                                </Select>
                                            )
                                        }}
                                    </Query>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="creatives-to-compare">Compare To</label>
                                    <Query query={CREATIVE_QUERY} >
                                       {({loading, error, data})=>{
                                            if(loading) return "Loadding ...";
                                            if(error) return `error! ${error.message}`;
                                            let options = data.creatives.map(d=>{return {value:d.name, label:d.name}})
                                            return(
                                                <Select value={this.state.selected_compare_to_creatives}
                                                        onChange={(e)=>{this.handleCreativeChange(e, "selected_compare_to_creatives")}}
                                                        options={options}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                        placeholder={"Compare To"}
                                                        classNamePrefix="custom-select"
                                                        className="custom-select"
                                                        >
                                                </Select>
                                            )
                                        }}
                                    </Query>
                                </div>
                        </div>
                        <div className="fields vertical">
                                <div className="form-group">
                                        <label htmlFor="start">Comparison Start Date</label>
                                        <DatePicker selected={this.state.start_comparison}
                                                onSelect={(date)=>{this.handleDateChange("start_comparison", date)}}
                                                className="form-control"
                                        />
                                </div> 
                                <div className="form-group">
                                        <label htmlFor="start">Comparison End Date</label>
                                        <DatePicker selected={this.state.end_comparison}
                                                onSelect={(date)=>{this.handleDateChange("end_comparison", date)}}
                                                className="form-control"
                                        />
                                </div>      
                        </div>
                        <div className="fields vertical">
                            <div className="form-group">
                                    <label htmlFor="creatives-to-compare">Min Spend</label>
                                    <input  className="form-control"
                                            type="number" 
                                            placeholder="Min Spend"
                                            name="min_spend"
                                            onChange={this.handleChange}
                                            />
                                </div>
                                <div className="form-group">
                                    {this.showQuery()}
                                </div>              
                        </div>
                </div>

            <TotalCards data={this.state.data_for_totals}
                        aggregateby={[{title:"Creatives ", 
                        accessor:"creative", 
                        fields:[
                                {title:"First 15 RAW Lift",
                                aggregate:"Lift",
                                accessor:["raw_15", "comparable_raw_15"],
                                type:"Percentage"
                                },
                                {
                                    title:"First 15 RAW Max Lift",
                                    aggregate:"Lift",
                                    accessor:["raw_15","max_raw_15"],
                                    type:"Percentage"
                                },
                                {title:"First 15 PAID Lift",
                                aggregate:"Lift",
                                accessor:["paid_15", "comparable_paid_15"],
                                type:"Percentage"
                                },
                                {title:"Overall Raw Lift",
                                aggregate:"Lift",
                                accessor:["raw", "comparable_raw"],
                                type:"Percentage"
                                }
                        ]}]}
            ></TotalCards> 
            <div className="card report-card">
                <div className="card-header">
                    <h5>{`${this.state.creatives.join(", ")} vs ${this.state.compare_to_creative.join(", ")}`}</h5>
                    <div className="actions">
                        {this.state.data? <button className="btn" onClick={this.exportReport}><i className="fa fa-arrow-down fa-2x"></i></button>:null}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {this.showData()}
                    </div>
                </div>
            </div>
            </div> 

        )
    }
}

export default privateRoute({requireLoggedIn:true})(CompareReportsOverview);