import React , {Component} from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import CallCenterStat from './call_center_stat';

import './callCenters.css';




const ACDSTATUSQUERY = gql`
query{
    acd_statuses{
        call_center
            skills{
          NAME
          agentsActive
          agentsInQueue
          callsInQueue
          agentsNotReadyForCalls
          agentsOnCall
        }
        agentsInQueue
        callsInQueue
        agentsActive
        agentsNotReadyForCalls
        agentsOnCall
      }
    }
`;


class CallCenters extends Component {
    constructor(props){
        super(props);

        this.state = {
            // day:moment(new Date()).startOf('day'),
        }

        // this.handleDateChange = this.handleDateChange.bind(this);
    }

    handleDateChange(dateName,value){
        this.setState({[dateName]:value, [`${dateName.replace('_date','')}`]:value});
    }

    render(){
        return(
            <div>
                <div>
                <Query query={ACDSTATUSQUERY}  pollInterval={100}>
                    
                    {({loading, error, data, time_stamp=new Date().valueOf(), stopPolling, startPolling}) =>{
                        if(loading) return `${'Loading'}`;
                        if(error) return stopPolling();
                        console.log(data);
                        if(data.acd_statuses){
                        return(
                            <div className="call-center-stats">
                                {data.acd_statuses.map((d)=>{
                                    return <CallCenterStat data={d} key={d.call_center}></CallCenterStat>
                                })}
                            </div>
                        );   
                        }else{
                            return error;
                        }
                    }}
                </Query>
                </div> 
            </div>
        )
    }
}


export default CallCenters